.car-state[data-v-00f32739] {
  display: flex;
  width: 0.8rem;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  min-width: 0px;
  cursor: pointer;
}
.state-selecter[data-v-00f32739] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  width: 7rem;
  padding: 0.1rem 0.4rem;
  border-radius: var(--radius);
}
.state-selecter[data-v-00f32739]:disabled:hover {
  background-color: transparent;
}
.car-state-container[data-v-00f32739] {
  padding: 0;
  margin: 0;
  padding-inline: 0;
  padding-block: 0;
}
.car-state-container[data-v-00f32739]:hover {
  background-color: transparent;
}
.car-state-container.-deleted[data-v-00f32739] {
  opacity: 0.4;
}