.cars-list-item[data-v-1a420619] {
  display: flex;
  padding: 0.4rem 0.8rem;
  border: 0.4px solid var(--color-border-primary);
  box-sizing: border-box;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-right: none;
  border-left: none;
  border-radius: 0;
}
.cars-list-item[data-v-1a420619]:not(.-first) {
  border-top: none;
}
.cars-list-item[data-v-1a420619]:has(.cars-list-item-update:hover), .cars-list-item[data-v-1a420619]:has(.car-state-container-parent:hover) {
  background-color: initial;
}
.cars-list-item-left[data-v-1a420619],
.cars-list-item-right[data-v-1a420619] {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.cars-list-item-right[data-v-1a420619] {
  justify-content: flex-end;
}
.car-name[data-v-1a420619],
.car-date[data-v-1a420619],
.car-invoice[data-v-1a420619] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  width: 10rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  gap: 0.4rem;
}
.car-name > span[data-v-1a420619],
.car-date > span[data-v-1a420619],
.car-invoice > span[data-v-1a420619] {
  color: var(--color-text-secondary);
}
.car-icon[data-v-1a420619] {
  width: 1.2rem;
  height: 1.2rem;
  min-width: 1.2rem;
  color: var(--color-icon);
}
.car-date[data-v-1a420619],
.car-invoice[data-v-1a420619] {
  width: fit-content;
}
.cars-list-item-slot[data-v-1a420619] {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
}
.replacement-car-icon[data-v-1a420619] {
  width: 1.4rem;
  height: 1.4rem;
  color: var(--color-text-submit);
}
.replacement-car-icon.-finished[data-v-1a420619] {
  color: var(--color-text-success);
}
.cars-list-item-update[data-v-1a420619] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  background-color: var(--color-button-bg-submit);
  color: var(--color-text-quaternary);
  padding: 0.2rem;
  width: 5.5rem;
  justify-content: center;
  opacity: 0.9;
}
.cars-list-item-update[data-v-1a420619]:hover {
  opacity: 1;
}
.car-works[data-v-1a420619] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  display: flex;
  width: 12.5rem;
  border-radius: var(--radius);
  overflow: hidden;
}
.car-works > span[data-v-1a420619] {
  color: var(--color-text-secondary);
}
.car-work[data-v-1a420619] {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-text-quaternary);
  min-width: 3rem;
}
.car-work.-sheet-metal[data-v-1a420619] {
  background-color: var(--color-bg-bar-sheet-metal-work);
}
.car-work.-paint[data-v-1a420619] {
  background-color: var(--color-bg-bar-painting);
}
.car-work.-mechanic[data-v-1a420619] {
  background-color: var(--color-bg-bar-mechanic);
}
.car-followup[data-v-1a420619] {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 0 0 0.4rem;
  min-width: 0.4rem;
  height: 100%;
}
.car-followup-point[data-v-1a420619] {
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 50%;
}
.car-followup-point.-incomplete[data-v-1a420619] {
  background-color: var(--car-followup-incomplete);
}
.car-followup-point.-complete[data-v-1a420619] {
  background-color: var(--car-followup-complete);
}
.tow-truck-icon[data-v-1a420619] {
  width: 1.2rem;
  height: 1.2rem;
  color: var(--color-icon);
}
.weck-delivered-icon[data-v-1a420619],
.invoice-ready-icon[data-v-1a420619] {
  color: var(--color-text-success);
}