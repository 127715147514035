.car[data-v-7fc5c2a0] {
  width: 100%;
  height: 100%;
  padding-top: 2rem;
  padding-bottom: 10rem;
  overflow-y: auto;
  margin: auto;
}
.car-container[data-v-7fc5c2a0] {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 1rem;
  width: 60%;
  margin: auto;
}
.car-details[data-v-7fc5c2a0] {
  display: flex;
  gap: 1rem;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.car__card-state.-big[data-v-7fc5c2a0] {
  width: 1.2rem;
  height: 1.2rem;
}
.car-title[data-v-7fc5c2a0] {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 3.5rem;
  color: var(--color-text-primary);
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.car-title span[data-v-7fc5c2a0] {
  color: var(--color-text-secondary);
}
.replacement-car-icon[data-v-7fc5c2a0] {
  width: 1.7rem;
  height: 1.7rem;
  color: var(--color-text-submit);
}
.replacement-car-icon.-finished[data-v-7fc5c2a0] {
  color: var(--color-text-success);
}
.car-row[data-v-7fc5c2a0] {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  align-items: center;
  color: var(--color-text-secondary);
}
.car-row svg[data-v-7fc5c2a0] {
  margin-right: -0.5rem;
}
.car-insurance[data-v-7fc5c2a0] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
}
.seriously-damaged-icon[data-v-7fc5c2a0] {
  color: var(--color-text-warning);
}
.tow-truck-icon[data-v-7fc5c2a0] {
  width: 1.8rem;
  height: 1.8rem;
  color: var(--color-icon);
}
.car-background-note[data-v-7fc5c2a0] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  width: 100%;
  padding: 1rem;
  border: 0.4px solid var(--color-border-secondary);
  border-radius: var(--radius);
  white-space: pre-wrap;
}
.car-folder-step[data-v-7fc5c2a0] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  color: var(--color-text-secondary);
}
.car-text[data-v-7fc5c2a0] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
}
.car-works[data-v-7fc5c2a0] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  display: flex;
  max-width: 8rem;
  min-width: 8rem;
  border-radius: var(--radius);
  overflow: hidden;
}
.car-works > span[data-v-7fc5c2a0] {
  color: var(--color-text-secondary);
}
.car-work[data-v-7fc5c2a0] {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-text-quaternary);
  min-width: 2rem;
}
.car-work.-sheet-metal[data-v-7fc5c2a0] {
  background-color: var(--color-bg-bar-sheet-metal-work);
}
.car-work.-paint[data-v-7fc5c2a0] {
  background-color: var(--color-bg-bar-painting);
}
.car-work.-mechanic[data-v-7fc5c2a0] {
  background-color: var(--color-bg-bar-mechanic);
}
.car-work-date[data-v-7fc5c2a0] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  margin-left: 1.6rem;
}
.car-work-date span[data-v-7fc5c2a0] {
  color: var(--color-text-secondary);
}
.car-work-date.-no-margin[data-v-7fc5c2a0] {
  margin-left: 0;
}
.car-history[data-v-7fc5c2a0] {
  --p-timeline-event-min-height: 3rem;
}
.car-history-log[data-v-7fc5c2a0] {
  display: inline-block;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  color: var(--color-text-secondary);
  width: 40rem;
  transform: translateY(-5px);
}
.car-history-point[data-v-7fc5c2a0] {
  width: 0.6rem;
  height: 0.6rem;
  background-color: var(--color-border-secondary);
  border-radius: var(--radius-max);
}