.car-followup-tooltip[data-v-971a70a3] {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 0.2rem;
}
.car-followup-point-container[data-v-971a70a3] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  display: flex;
  gap: 0.4rem;
  align-items: center;
  width: 100%;
}
.car-followup-point-container.-hidden[data-v-971a70a3] {
  display: none;
}
.followup[data-v-971a70a3] {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
}
.followup.-incomplete[data-v-971a70a3] {
  color: var(--color-text-warning);
}
.followup.-complete[data-v-971a70a3] {
  color: var(--color-text-success);
}