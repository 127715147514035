.insurances-container[data-v-bf40d8b7] {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.6rem;
  margin-top: 0.6rem;
}
.insurances-selecter[data-v-bf40d8b7] {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  height: 20rem;
}
.insurance-selecter.-footer[data-v-bf40d8b7] {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.insurance-selecter[data-v-bf40d8b7] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
}
.insurance-add[data-v-bf40d8b7] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  padding: 0.4rem;
  border-radius: var(--radius);
  background-color: var(--color-button-bg-submit-full);
  color: var(--color-text-quaternary);
  width: 50%;
  margin: 1rem;
}
.insurance-add[data-v-bf40d8b7]:hover {
  background-color: var(--color-button-bg-submit-full-hover);
}
.insurance-box-container[data-v-bf40d8b7] {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 0.4rem;
  max-width: 26rem;
}
.insurance-box[data-v-bf40d8b7] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  display: flex;
  align-items: center;
  gap: 0.4rem;
  background-color: var(--color-button-bg-object);
  color: var(--color-text-quaternary);
  padding: 0.2rem 0.8rem;
  border-radius: var(--radius-max);
}
.remove-insurance[data-v-bf40d8b7] {
  color: var(--color-text-quaternary);
}
.remove-insurance[data-v-bf40d8b7]:hover {
  color: var(--color-bg-warning);
  background-color: transparent;
}