.cars-list-container[data-v-bcd72d5a] {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
}
.cars-list-header[data-v-bcd72d5a] {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 3.5rem;
  color: var(--color-text-primary);
  display: block;
  padding: 0.4rem 0.8rem;
}
.cars-list-header[data-v-bcd72d5a]::before {
  content: attr(data-state);
}
.sentinel[data-v-bcd72d5a] {
  min-height: 100px;
}