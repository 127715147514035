.input-maintenance-time[data-v-90af3471], .label-maintenance-time[data-v-90af3471] {
  background: var(--content-bg--color-lighter) !important;
}
.label-maintenance-time[data-v-90af3471] {
  color: var(--color-text-secondary);
}
.label-maintenance-time.-icon[data-v-90af3471] {
  display: flex;
  align-items: center;
  gap: 0.4rem;
}
.input-maintenance-time[data-v-90af3471] {
  color: var(--color-text-primary);
}
.maintenance-time-container[data-v-90af3471] {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 0.4rem;
}
.maintenance-time-dates[data-v-90af3471] {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 30rem;
}
.maintenance-time-form-validate[data-v-90af3471] {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 1.4rem;
}
.maintenance-time-form-button[data-v-90af3471] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  color: var(--color-text-quaternary);
  background-color: var(--color-button-bg-submit-full);
  padding: 0.4rem;
  width: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.maintenance-time-form-button[data-v-90af3471]:hover {
  background-color: var(--color-button-bg-submit-full-hover);
}
.label-maintenance-time[data-v-90af3471] {
  background: var(--content-bg--color-lighter) !important;
}