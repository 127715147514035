.replacement-car[data-v-15226583] {
  width: 100%;
  height: 100%;
  padding-top: 2rem;
  padding-bottom: 10rem;
  overflow-y: auto;
  margin: auto;
}
.replacement-car-container[data-v-15226583] {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 1rem;
  width: 60%;
  margin: auto;
}
.replacement-car-details[data-v-15226583] {
  display: flex;
  gap: 1rem;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.replacement-car-title[data-v-15226583] {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 3.5rem;
  color: var(--color-text-primary);
}
.replacement-car-title span[data-v-15226583] {
  color: var(--color-text-secondary);
}
.replacement-car-insurances[data-v-15226583] {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}
.replacement-car-insurance[data-v-15226583] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  padding: 0.2rem 0.8rem;
  border: 0.4px solid var(--color-border-secondary);
  border-radius: var(--radius-max);
}
.replacement-car-insurances-empty[data-v-15226583] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  color: var(--color-text-secondary);
}
.replacement-car-timeline[data-v-15226583] {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 2rem !important;
  width: 60%;
  margin: auto;
}
.replacement-car-icon[data-v-15226583] {
  color: var(--color-text-quaternary);
}
.replacement-car-marker-date[data-v-15226583] {
  background-color: var(--color-button-bg-submit);
  border-radius: 999px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  min-width: 20px;
}
.replacement-car-marker-date.-end[data-v-15226583] {
  background-color: var(--color-text-success);
}
.replacement-car-marker[data-v-15226583] {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  align-self: baseline;
  border-width: 2px;
  border-style: solid;
  border-color: var(--color-text-secondary);
  border-radius: 999px;
  width: 18px;
  height: 18px;
}
.replacement-car-marker.-disabled[data-v-15226583] {
  color: var(--color-text-warning);
  background-color: var(--color-text-warning);
  border-color: var(--color-text-warning);
}
.replacement-car-date[data-v-15226583] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.4rem;
  transform: translateY(-0.2rem);
}
.replacement-car-owner[data-v-15226583] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  text-decoration: none;
  transform: translateY(-0.2rem);
}
.maintenance-time-buttons[data-v-15226583] {
  display: flex;
  gap: 0.4rem;
}
.maintenance-time-button[data-v-15226583] {
  color: var(--color-icon);
  opacity: 0.6;
}
.maintenance-time-button[data-v-15226583]:hover {
  opacity: 1;
  background-color: transparent;
  color: var(--color-icon-focus);
}
.maintenance-time-button.-delete[data-v-15226583]:hover {
  color: var(--color-text-warning);
}
.replacement-car-state-of-play[data-v-15226583] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  width: 60%;
  margin: 1rem auto;
  padding: 1rem;
  border: 0.4px solid var(--color-border-secondary);
  border-radius: var(--radius);
  white-space: pre-wrap;
}