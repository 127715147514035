
.cars-list-loading-data[data-v-518ac7d5] {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
}
.loading-icon[data-v-518ac7d5] {
  color: var(--color-text-primary);
  font-size: 2rem;
}
