.car-incharge-form-validate[data-v-ca0ced5e] {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 1.4rem;
}
.car-incharge-form-button[data-v-ca0ced5e] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  color: var(--color-text-quaternary);
  background-color: var(--color-button-bg-submit-full);
  padding: 0.4rem;
  width: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.car-incharge-form-button[data-v-ca0ced5e]:hover {
  background-color: var(--color-button-bg-submit-full-hover);
}
.car-incharge-form-button.-long[data-v-ca0ced5e] {
  width: 14rem;
  align-self: center;
}
.loading-icon[data-v-ca0ced5e] {
  color: var(--color-text-primary);
  font-size: 1.2rem;
  margin-right: 3rem;
}