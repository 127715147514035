.car-incharge-form-block[data-v-57e08221] {
  display: flex;
  flex-direction: column;
  padding: 0.4rem 0rem;
  gap: 1rem;
  width: 100%;
}
.car-incharge-form-block.-row[data-v-57e08221] {
  flex-direction: row;
  justify-content: space-between;
}
.car-incharge-form-block.-no-padding[data-v-57e08221] {
  padding: 0;
}
.car-form-subtitle[data-v-57e08221] {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 2.5rem;
  color: var(--color-text-primary);
  display: flex;
  align-items: center;
  gap: 0.4rem;
  margin-top: 0.4rem;
}
.car-form-subtitle-icon[data-v-57e08221] {
  width: 1.5rem;
  height: 1.5rem;
}
.car-form-checkbox[data-v-57e08221] {
  display: flex;
  align-items: center;
  gap: 0.6rem;
  width: fit-content;
  padding-inline: 0;
  padding-block: 0;
}
.car-form-checkbox[data-v-57e08221]:hover {
  background-color: var(--content-bg--color-lighter);
}
.state-value[data-v-57e08221] {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}