.car-incharge-form-block[data-v-6152e032] {
  display: flex;
  flex-direction: column;
  padding: 0.4rem 0rem;
  gap: 1rem;
  width: 100%;
}
.car-incharge-form-block.-row[data-v-6152e032] {
  flex-direction: row;
  justify-content: space-between;
}
.car-incharge-form-block.-no-padding[data-v-6152e032] {
  padding: 0;
}
.car-incharge-form-block.-no-gap[data-v-6152e032] {
  gap: 0;
}
.car-form-subtitle[data-v-6152e032] {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 2.5rem;
  color: var(--color-text-primary);
  display: flex;
  align-items: center;
  gap: 0.4rem;
  margin-top: 0.4rem;
}
.car-form-dropdown[data-v-6152e032] {
  color: var(--color-text-secondary);
}
.car-form-dropdown[data-v-6152e032]:hover {
  color: var(--color-text-primary);
}
.car-form-subtitle-icon[data-v-6152e032] {
  width: 1.5rem;
  height: 1.5rem;
}
.car-form-checkbox[data-v-6152e032] {
  display: flex;
  align-items: center;
  gap: 0.6rem;
  width: fit-content;
  padding-inline: 0;
  padding-block: 0;
  opacity: 1;
}
.car-form-checkbox[data-v-6152e032]:hover {
  background-color: var(--content-bg--color-lighter);
}
.car-form-checkbox.-hide[data-v-6152e032] {
  display: none;
}
.end-valid-support-date[data-v-6152e032] {
  transform: translateY(1.2rem);
  margin-bottom: 0.8rem;
}
.car-form-subtitle-chevron[data-v-6152e032] {
  transition: transform var(--p-transition-duration);
}
.car-form-subtitle-chevron.-rotate[data-v-6152e032] {
  transform: rotate(-180deg);
}
.list-enter-active[data-v-6152e032] {
  transition: all var(--p-transition-duration) ease;
}
.list-leave-active[data-v-6152e032] {
  transition: all 0s ease;
}
.list-enter-from[data-v-6152e032],
.list-leave-to[data-v-6152e032] {
  opacity: 0;
  transform: translateX(30px);
}