.car-incharge-form-block[data-v-4842b07a] {
  display: flex;
  flex-direction: column;
  padding: 0.4rem 0rem;
  gap: 1rem;
  width: 100%;
}
.car-incharge-form-block.-row[data-v-4842b07a] {
  flex-direction: row;
  justify-content: space-between;
}
.car-incharge-form-block.-no-padding[data-v-4842b07a] {
  padding: 0;
}