.replacement-car-tooltpip[data-v-d1b14896] {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}
.replacement-car-name[data-v-d1b14896],
.replacement-car-date[data-v-d1b14896] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  color: var(--color-text-quaternary);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.4rem;
}
.label-text.-finished[data-v-d1b14896] {
  color: var(--color-text-success);
}
.label-text.-waiting[data-v-d1b14896] {
  color: var(--color-text-secondary);
}
.label-text.-start.-finished[data-v-d1b14896] {
  color: var(--color-button-bg-submit);
}
.replacement-car-marker[data-v-d1b14896] {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  align-self: baseline;
  border-width: 2px;
  border-style: solid;
  border-color: var(--color-text-secondary);
  border-radius: 999px;
  width: 18px;
  height: 18px;
  align-self: center;
}
.replacement-car-icon[data-v-d1b14896] {
  color: var(--color-text-quaternary);
}
.replacement-car-marker-date[data-v-d1b14896] {
  background-color: var(--color-button-bg-submit);
  border-radius: 999px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  min-width: 18px;
}
.replacement-car-marker-date.-end[data-v-d1b14896] {
  background-color: var(--color-text-success);
}