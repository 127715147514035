.document-file-box[data-v-f342a601] {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--color-border-secondary);
  padding: 0.4rem;
  height: 4rem;
  gap: 1rem;
  border-radius: var(--radius);
  margin-bottom: 0.6rem;
  margin-top: 0.6rem;
  overflow: visible;
}
.document-file[data-v-f342a601] {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.document-label[data-v-f342a601] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  color: var(--color-text-secondary);
  position: absolute;
  transform: translateY(-85%) translateX(6%);
  background-color: var(--content-bg--color-light);
  width: 4.2rem;
  text-align: center;
}
.document-file-detail[data-v-f342a601] {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.document-file-detail.-small-gap[data-v-f342a601] {
  gap: 0.4rem;
}
.document-file-text[data-v-f342a601] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 25rem;
  padding-inline: 6px;
}
.document-file-text.-secondary[data-v-f342a601] {
  color: var(--color-text-secondary);
}