.maintenance-time-message[data-v-e8b2484b] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
}
.maintenance-time-buttons[data-v-e8b2484b] {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 0.4rem;
}
.maintenance-time-form-button[data-v-e8b2484b] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  color: var(--color-text-quaternary);
  background-color: var(--color-button-bg-submit-full);
  padding: 0.2rem;
  width: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2.25rem;
}
.maintenance-time-form-button[data-v-e8b2484b]:hover {
  background-color: var(--color-button-bg-submit-full-hover);
}
.maintenance-time-form-button.-deletion[data-v-e8b2484b] {
  background-color: var(--color-bg-warning);
}
.maintenance-time-form-button.-deletion[data-v-e8b2484b]:hover {
  background-color: var(--color-text-warning);
}