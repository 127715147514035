.car-repairing-states[data-v-7728aadd] {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  margin-top: 0.4rem;
}
.car-repairing-state[data-v-7728aadd] {
  display: flex;
  align-items: center;
  gap: 0.4rem;
}
.car-repairing-state.-disable[data-v-7728aadd] {
  opacity: 0.3;
}