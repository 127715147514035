.cars-list-toolbar[data-v-a0f679c6] {
  display: flex;
  padding: 1rem;
  gap: 1rem;
  border-bottom: 0.4px solid var(--color-border-primary);
}
.cars-list-search-reset[data-v-a0f679c6] {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cars-list-search-reset-icon[data-v-a0f679c6] {
  color: var(--color-icon);
}
.cars-list-search-reset:hover .cars-list-search-reset-icon[data-v-a0f679c6] {
  color: var(--color-icon-hover);
}