.replacement-car-form-block[data-v-2ba22f5d] {
  display: flex;
  flex-direction: column;
  padding: 0.4rem 0rem;
  gap: 1rem;
  width: 100%;
}
.replacement-car-form-block.-row[data-v-2ba22f5d] {
  flex-direction: row;
  justify-content: space-between;
}
.replacement-car-form-block.-no-padding[data-v-2ba22f5d] {
  padding: 0;
}
.input-replacement-car[data-v-2ba22f5d], .label-replacement-car[data-v-2ba22f5d] {
  background: var(--content-bg--color-lighter) !important;
  color: var(--color-text-primary);
}
.input-replacement-car.-textarea[data-v-2ba22f5d], .label-replacement-car.-textarea[data-v-2ba22f5d] {
  padding: 0.4rem;
  border-radius: var(--radius);
  margin-top: 1rem;
  width: 100%;
}
.replacement-car-form-validate[data-v-2ba22f5d] {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 1.4rem;
}
.replacement-car-form-button[data-v-2ba22f5d] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  color: var(--color-text-quaternary);
  background-color: var(--color-button-bg-submit-full);
  padding: 0.4rem;
  width: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.replacement-car-form-button[data-v-2ba22f5d]:hover {
  background-color: var(--color-button-bg-submit-full-hover);
}