.document-file-box[data-v-02585f92] {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--p-form-field-border-color);
  padding: 0.4rem;
  height: 4rem;
  gap: 1rem;
  border-radius: var(--radius);
  margin-bottom: 0.6rem;
  margin-top: 0.6rem;
  overflow: visible;
}
.document-file[data-v-02585f92] {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.document-label[data-v-02585f92] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  color: var(--p-floatlabel-active-color);
  position: absolute;
  transform: translateY(-80%) translateX(6%);
  background-color: var(--content-bg--color-lighter);
  width: fit-content;
  padding: 0 0.1rem;
  text-align: center;
}
.document-file-detail[data-v-02585f92] {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.document-file-detail.-small-gap[data-v-02585f92] {
  gap: 0.4rem;
}
.document-file-text[data-v-02585f92] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 16rem;
}
.document-file-text.-secondary[data-v-02585f92] {
  color: var(--color-text-secondary);
}
.document-file-text.-success[data-v-02585f92] {
  color: var(--color-text-success);
}
.document-file-text.-failure[data-v-02585f92] {
  color: var(--color-text-warning);
}
.document-file-trash[data-v-02585f92] {
  color: var(--color-text-secondary);
}
.document-file-success[data-v-02585f92] {
  color: var(--color-text-success);
  margin-left: 6px;
}
.document-file-failure[data-v-02585f92] {
  color: var(--color-text-warning);
}
.document-file-button[data-v-02585f92] {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.document-file-button:hover .document-file-trash[data-v-02585f92] {
  color: var(--color-bg-warning);
}
.document-upload-progress-bar[data-v-02585f92] {
  width: 100%;
  height: 1rem;
  background: var(--content-bg--color-light);
  border-radius: var(--radius-max);
  margin-top: 0.4rem;
  overflow: hidden;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.313);
}
.document-upload-progress[data-v-02585f92] {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 0.6rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  height: 100%;
  background: var(--content-bg--color-focus);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  line-height: 1rem;
  transition: width 0.3s ease-in-out;
}
.file-input[data-v-02585f92] {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-s);
  width: 100%;
  height: 4rem;
  border: 1px dashed var(--color-border-secondary);
  border-radius: var(--radius);
  flex-direction: column;
  cursor: pointer;
  transition: background 0.3s ease;
  margin-bottom: 0.6rem;
  background: var(--content-bg--color-lighter);
}
.file-input[data-v-02585f92]:hover {
  background: var(--content-bg--color-focus);
}
.file-input-icon[data-v-02585f92] {
  color: var(--color-text-secondary);
  transition: color 0.3s ease;
}
.document-file-input[data-v-02585f92] {
  opacity: 0;
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
}
.document-file-label[data-v-02585f92] {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
  color: var(--color-text-secondary);
  transition: color 0.3s ease;
}
.document-upload-box:hover .document-upload-icon[data-v-02585f92],
.document-upload-box:hover .document-file-label[data-v-02585f92] {
  color: var(--color-text-primary);
}